import React from "react"

import { Container } from 'reactstrap'
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-quotes' 
import worldIcon from '../../assets/images/world.png'
import truckAbsolute from '../../assets/images/quotes-truck.png'
 
export default () => (
  <LayoutEsp>
    <SEO title="Cotizaciones" />
    <Slider title="COTIZACIONES"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="credtiH">{'COTIZACIONES'}</h4>   
				<img className="truckAbsolute4" src={truckAbsolute} alt="truck"/> 
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack"> 
					<p className="grayP">{'!Estamos listos para trabajar junto a ti!'}</p> 
					<p className="grayText2">Mandanos un correo a <a href="mailto:Quotes@gutierreztrucking.com">Quotes@gutierreztrucking.com</a> para poder recibir pronto tu cotizacion.</p>
				</div>
			  </div> 
			</div>
		</Container>
	</div>
  </LayoutEsp>
)
